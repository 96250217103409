import "./App.css"
import { useEffect, useState, useMemo } from 'react';
import { NavLink, Outlet } from "react-router-dom";
import {
  MDBNavbarBrand,
  MDBCollapse,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBContainer,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBIcon,
  MDBBtn,
  MDBModalDialog,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBCheckbox,
} from 'mdb-react-ui-kit';
import Footer from './components/Footer';
import logo from './avs-bot.png';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import AlertTitle from '@mui/material/AlertTitle';
import React from 'react';
import Slide from '@mui/material/Slide';

export function App() {
  const [showBasic, setShowBasic] = useState(false);
  const [header, setHeader] = useState("header");
  const [basicModal, setBasicModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [view, setView] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const listenScrollEvent = () => {
    if (window.scrollY < 5) {
      return setHeader("header3");
    } else if (window.scrollY > 0) {
      return setHeader("header2");
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  function toggle() {
    setIsOpened(wasOpened => !wasOpened);
  }

  (window as any).toggle = toggle;

  function toggleCookiesConsent() {
    gtag('consent', 'update', {
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'ad_storage': 'granted',
      'analytics_storage': 'granted'
    });
    setBasicModal(!basicModal);
    setChecked(false);
  }

  (window as any).toggleCookiesConsent = toggleCookiesConsent;

  const handleChange = () => {
    setChecked((prev) => !prev);

    gtag('consent', 'update', {
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'ad_storage': 'granted',
      'analytics_storage': 'granted'
    });

  };

  (window as any).handleChange = handleChange;

  window.onload = (event) => {
    handleChange();
    setView((val) => !val);
    console.log("page is fully loaded");
  };

  return (

    <MDBContainer fluid>
      <header>
        {menuFunction(header, showBasic, setShowBasic)}
      </header>

      <div id="body-main">
        <Outlet />
      </div>

      <div className="fixed-action-btn">
        <img alt="Chatbot" className="chatbot" src='../images/avs-bot.png' width='150px' onClick={toggle} />
       

        {isOpened && (
          <div className="boxContent">
            <iframe title="WebChat" src='https://europe.webchat.botframework.com/embed/avs-web-bot?s=Tot000H7j8k.iQgTbuddnY1kvVyEMs4chDJ3u7l-lQ-57W0fQ3CvFxc' className='BotStyle' style={{ minWidth: '400px', width: '100%', minHeight: '500px' }}></iframe>
          </div>
        )}
      </div>

      {cookiesFunction(checked, toggleCookiesConsent, handleChange)}

      {cookiesModalFunction(basicModal, setBasicModal, toggleCookiesConsent)}

      <Footer />

    </MDBContainer>
  );
}

export default App;

function cookiesModalFunction(basicModal: boolean, setBasicModal: React.Dispatch<React.SetStateAction<boolean>>, toggleCookiesConsent: () => void) {
  return <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
    <MDBModalDialog>
      <MDBModalContent>
        <MDBModalHeader>
          <MDBModalTitle>Cookies Consent</MDBModalTitle>
          <MDBBtn className='btn-close' color='none' onClick={toggleCookiesConsent}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
          When you visit any of our websites, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences, or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and manage your preferences. Please note, blocking some types of cookies may impact your experience of the site and the services we are able to offer.
          Cookie Policy <br /><br />
          <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Enables storage related to advertising' />
          <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Send User Data to Google for advertising purposes' />
          <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Personalized Advertising' />
          <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Enables Storage, such as cookies related to analytics' />
        </MDBModalBody>

        <MDBModalFooter>
          <MDBBtn color='secondary' onClick={toggleCookiesConsent}>
            Close
          </MDBBtn>
          <MDBBtn onClick={toggleCookiesConsent}>Save changes</MDBBtn>
        </MDBModalFooter>
      </MDBModalContent>
    </MDBModalDialog>
  </MDBModal>;
}

function cookiesFunction(checked: boolean, toggleCookiesConsent: () => void, handleChange: () => void) {
  return <React.Fragment>
    <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
      <Alert icon={<CheckIcon fontSize="inherit" />} severity="info" className="cookies">
        <AlertTitle>We use cookies</AlertTitle>
        Cookies help us deliver the best experience on our website.
        <br />By using our website, you agree to the use of cookies.
        <a onClick={toggleCookiesConsent} target="_blank" rel="noopener noreferrer" style={{ cursor: 'pointer' }}>
          Find out how we use cookies.
        </a><br /><br />
        <MDBBtn onClick={handleChange}>ACCEPT</MDBBtn>
      </Alert>
    </Slide>
  </React.Fragment>;
}

function menuFunction(header: string, showBasic: boolean, setShowBasic: React.Dispatch<React.SetStateAction<boolean>>) {
  return <MDBNavbar expand='lg' dark fixed='top' scrolling className={header}>
    <MDBContainer sm>
      <MDBNavbarBrand href='#'>
        <img
          className='mb-1'
          src='/images/avision-logo-new-trans.png'
          style={{ width: 170, height: 60 }}
          about='AVision Software'
          alt='AVision Software' />
      </MDBNavbarBrand>
      <MDBCollapse navbar open={showBasic}>
        <MDBNavbarNav right className='mb-2 mb-lg-0'>
          <MDBNavbarItem>
            <MDBNavbarLink>
              <nav id="home">
                <NavLink to='/'>
                  Home
                </NavLink>
              </nav>
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink>
              <nav id="applications">
                <NavLink to='/applications'>
                  AI-Driven Value
                </NavLink>
              </nav>
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink>
              <nav id="technology">
                <NavLink to='/technology'>
                  Technology
                </NavLink>
              </nav>
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBDropdown>
              <nav id='categories'>
                <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                  Categories
                </MDBDropdownToggle>
              </nav>
              <MDBDropdownMenu>
                <NavLink to="/categories"><MDBDropdownItem link>AI Advanced Analytics</MDBDropdownItem></NavLink>
                <NavLink to="/categories"><MDBDropdownItem link>AI Video Analytics</MDBDropdownItem></NavLink>
                <NavLink to="/categories"><MDBDropdownItem link>AI Digital Twins</MDBDropdownItem></NavLink>
                <NavLink to="/categories"><MDBDropdownItem link>AI Cyber Security</MDBDropdownItem></NavLink>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink>
              <nav id="responsibility">
                <NavLink to='/responsibility'>
                  Responsibility
                </NavLink>
              </nav>
            </MDBNavbarLink>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBCollapse>

      <MDBNavbarNav className='d-flex flex-row' right fullWidth={false}>
        {/* <Notifications />
      <LanguageSelect />
      <Avatar /> */}
        <MDBBtn tag="a" outline size="lg">
          <NavLink to='https://avision.world' target="_blank" rel="noopener noreferrer">
            AVISION.WORLD
          </NavLink>
        </MDBBtn>
      </MDBNavbarNav>

      <MDBNavbarToggler
        onClick={() => setShowBasic(!showBasic)}
        aria-controls='navbarExample01'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <MDBIcon fas icon='bars' color='white' />
      </MDBNavbarToggler>
    </MDBContainer>
  </MDBNavbar>;
}